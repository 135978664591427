@import "nlib/config";

.fieldContainer {
  position: relative;
  justify-content: space-between;
  padding: $contentDefaultIndent / 2 $contentDefaultIndent / 2 $contentDefaultIndent / 2 $contentDefaultIndent;
  box-shadow: inset 0 1px 0 #f0f0f0;
  .container {
    display: flex;
    flex: 1;
    align-items: center;
    min-height: 2.5rem;
  }
  .title {
    display: flex;
    align-items: center;
    font-size: 0.675rem;
    line-height: 1.125rem;
    .text {
      display: flex;
      align-items: center;
      margin-right: 0.3em;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: uppercase;
      white-space: nowrap;
      svg {
        font-size: 0.875rem;
      }
    }
    .confidence {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      width: $contentDefaultIndent * 4;
      height: $contentDefaultIndent * 2;
      margin-left: auto;
      border-radius: $uiBorderRadius;
      font-weight: 500;
      line-height: 1;
      text-align: center;
    }
  }
  .value {
    display: flex;
    flex: auto;
    align-items: center;
    height: 2rem;
    margin-right: $contentDefaultIndent / 2;
    overflow: hidden;
    font-weight: 500;
    .placeholder {
      color: $uiPlaceholderColor;
      font-weight: normal;
    }
    div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .input {
    flex: 1;
    margin-right: $contentDefaultIndent / 2;
  }
  .button {
    text-transform: none;
  }
  .iconButton {
    min-width: 2rem;
    height: 2rem;
    padding: 0;
    background: none;
    svg {
      font-size: 1.125rem;
    }
    &.saveButton {
      color: $uiHighlightColor;
    }
  }
  &.invalid {
    position: sticky;
    z-index: 1;
    top: 0;
    bottom: 0;
    &:not(:hover) {
      background-color: lighten(mix($uiAccentColor, $uiWarningColor), 38%) !important;
    }
    .text {
      color: $uiNegativeColor;
    }
    .placeholder {
      color: #e7b5a0;
    }
  }
  &.active {
    z-index: 2;
  }
  &.editable {
    cursor: pointer;
  }
}
